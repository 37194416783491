"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";

const Blog = ({ title, paragraph, blogClass, buttonText }) => {
  const [posts, setPosts] = useState([]);

  //const apiUrl = process.env.NEXT_PUBLIC_API_URL + "/wp-json/wp/v2/posts";
  const apiUrl = process.env.NEXT_PUBLIC_API_URL + "/wp-json/wp/v2/posts?per_page=4";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setPosts(data.slice(0, 3)); // Fetch top 3 blogs
      } catch (error) {
        console.log(error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <section className="pb-124 homepage-blogs">
      <div className="container">
        <div className="text-center font24 block mb-50">
          <h2 className="font44 fw-600 title-border title-bordercenter mt-0 mb-30">
            {title}
          </h2>
          <p>{paragraph}</p>
        </div>
        {/* <div className="row hiring-wrapper d-flex_hire flex-wrap"> */}
        <div
          className={`row hiring-wrapper d-flex flex-wrap ${blogClass ? "d-flex_hire" : ""
            }`}
        >
          {posts?.map((post) => (
            <div key={post.id} className="col-xs-6 col-sm-4">
              <div className="blog-wrapper">
                <a href={post.link}>
                  <div className="blog-image mb-15">
                    <Image
                      className="lazy"
                      src={
                        post.featured_image_url ||
                        "https://cdn.moontechnolabs.com/live/images/services/Feature-image.webp"
                      }
                      alt={
                        post.categories_list?.categories_name?.join(", ") ||
                        "Blog"
                      }
                      width={333}
                      height={181}
                      loading="lazy"
                      unoptimized
                    />
                  </div>
                  <div className="blog-content">
                    <div className="category">
                      <span className="text-primary mb-15 d-block fw-700">
                        {post.categories_list?.categories_name?.join(", ")}
                      </span>
                    </div>
                    <div className="blog_desc">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      />
                    </div>
                    <div className="blog_date">
                      <Image
                        fetchpriority="low"
                        as="image"
                        className="lazy"
                        src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/calendar.svg`}
                        alt="Calendar Icon"
                        width={30}
                        height={20}
                        loading="lazy"
                      />
                      <span>
                        {new Date(post.date)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .toUpperCase()}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="buttons-block text-center pt-50 pb_50">
          <a
            href={`${process.env.NEXT_PUBLIC_API_URL}/blog/}`}
            type="button"
            className="btn_hover ios_btn"
            title={buttonText || "Read All Blogs"}
          >
            {buttonText || "Read All Blogs"}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Blog;
