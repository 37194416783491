"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  // const apiUrl = process.env.NEXT_PUBLIC_API_URL + "/blog/wp-json/wp/v2/posts";
  //const apiUrl = process.env.NEXT_PUBLIC_API_URL + "/wp-json/wp/v2/posts";
  const apiUrl = process.env.NEXT_PUBLIC_API_URL + "/wp-json/wp/v2/posts?per_page=4";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setPosts(data.slice(0, 3));
      } catch (error) {
        console.log(error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <>
      <section className="pt-70">
        <div className="container_wrap">
          <div className="col-12">
            <div className="trusted_client ios_services">
              <h2>Top Blogs</h2>
              <p className="ios_inner_text">
                Stay informed and inspired with our selection of expertly
                curated blogs covering a wide range of topics and industries.
              </p>
            </div>
          </div>
          <div className="row blog-height-100">
            {posts?.map((post) => (
              <div
                className="col-lg-4 col-md-6 col-sm-6 col-12 pt-30"
                key={post.id}
              >
                <Link href={post.link}>
                  <div className="blog_card_box">
                    <div className="blog_img">
                      <Image
                        src={
                          post.featured_image_url ||
                          "https://cdn.moontechnolabs.com/live/images/services/Feature-image.webp"
                        }
                        alt={post.guid.rendered}
                        width={352}
                        height={226}
                        loading="lazy"
                        unoptimized
                      />
                      <span>
                        {post.categories_list?.categories_name?.join(", ")}
                      </span>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      />
                    </div>
                    <div className="blog_date">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.66699 1.0415C7.01217 1.0415 7.29199 1.32133 7.29199 1.6665V4.1665C7.29199 4.51168 7.01217 4.7915 6.66699 4.7915C6.32181 4.7915 6.04199 4.51168 6.04199 4.1665V1.6665C6.04199 1.32133 6.32181 1.0415 6.66699 1.0415Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.333 1.0415C13.6782 1.0415 13.958 1.32133 13.958 1.6665V4.1665C13.958 4.51168 13.6782 4.7915 13.333 4.7915C12.9878 4.7915 12.708 4.51168 12.708 4.1665V1.6665C12.708 1.32133 12.9878 1.0415 13.333 1.0415Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.29199 7.5752C2.29199 7.23002 2.57181 6.9502 2.91699 6.9502H17.0837C17.4288 6.9502 17.7087 7.23002 17.7087 7.5752C17.7087 7.92037 17.4288 8.2002 17.0837 8.2002H2.91699C2.57181 8.2002 2.29199 7.92037 2.29199 7.5752Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.94758 4.48762C3.41364 5.06263 3.125 5.92898 3.125 7.08317V14.1665C3.125 15.3207 3.41364 16.187 3.94758 16.7621C4.47373 17.3287 5.32746 17.7082 6.66667 17.7082H13.3333C14.6725 17.7082 15.5263 17.3287 16.0524 16.7621C16.5864 16.187 16.875 15.3207 16.875 14.1665V7.08317C16.875 5.92898 16.5864 5.06263 16.0524 4.48762C15.5263 3.92099 14.6725 3.5415 13.3333 3.5415H6.66667C5.32746 3.5415 4.47373 3.92099 3.94758 4.48762ZM3.03159 3.63706C3.8596 2.74535 5.08921 2.2915 6.66667 2.2915H13.3333C14.9108 2.2915 16.1404 2.74535 16.9684 3.63706C17.7886 4.52038 18.125 5.73736 18.125 7.08317V14.1665C18.125 15.5123 17.7886 16.7293 16.9684 17.6126C16.1404 18.5043 14.9108 18.9582 13.3333 18.9582H6.66667C5.08921 18.9582 3.8596 18.5043 3.03159 17.6126C2.21136 16.7293 1.875 15.5123 1.875 14.1665V7.08317C1.875 5.73736 2.21136 4.52038 3.03159 3.63706Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.2451 11.4168C12.2451 10.9566 12.6182 10.5835 13.0785 10.5835H13.0859C13.5462 10.5835 13.9193 10.9566 13.9193 11.4168C13.9193 11.8771 13.5462 12.2502 13.0859 12.2502H13.0785C12.6182 12.2502 12.2451 11.8771 12.2451 11.4168Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.2451 13.9168C12.2451 13.4566 12.6182 13.0835 13.0785 13.0835H13.0859C13.5462 13.0835 13.9193 13.4566 13.9193 13.9168C13.9193 14.3771 13.5462 14.7502 13.0859 14.7502H13.0785C12.6182 14.7502 12.2451 14.3771 12.2451 13.9168Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.16309 11.4168C9.16309 10.9566 9.53618 10.5835 9.99642 10.5835H10.0039C10.4641 10.5835 10.8372 10.9566 10.8372 11.4168C10.8372 11.8771 10.4641 12.2502 10.0039 12.2502H9.99642C9.53618 12.2502 9.16309 11.8771 9.16309 11.4168Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.16309 13.9168C9.16309 13.4566 9.53618 13.0835 9.99642 13.0835H10.0039C10.4641 13.0835 10.8372 13.4566 10.8372 13.9168C10.8372 14.3771 10.4641 14.7502 10.0039 14.7502H9.99642C9.53618 14.7502 9.16309 14.3771 9.16309 13.9168Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.07812 11.4168C6.07812 10.9566 6.45122 10.5835 6.91146 10.5835H6.91894C7.37918 10.5835 7.75228 10.9566 7.75228 11.4168C7.75228 11.8771 7.37918 12.2502 6.91894 12.2502H6.91146C6.45122 12.2502 6.07812 11.8771 6.07812 11.4168Z"
                          fill="#656565"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.07812 13.9168C6.07812 13.4566 6.45122 13.0835 6.91146 13.0835H6.91894C7.37918 13.0835 7.75228 13.4566 7.75228 13.9168C7.75228 14.3771 7.37918 14.7502 6.91894 14.7502H6.91146C6.45122 14.7502 6.07812 14.3771 6.07812 13.9168Z"
                          fill="#656565"
                        ></path>
                      </svg>
                      <span>
                        {new Date(post.date).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div class="blog_button">
            <a href={`${process.env.NEXT_PUBLIC_API_URL}/blog/}`}>
              Read All Blogs
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
